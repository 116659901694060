import { useQuery } from 'react-query';
import axiosInstance from '../../Store/helper/axiosInstance';

import {
  USER_ACTIVITY_LIST,
  USER_CME_POINTS_ACHIVED,
  USER_GOAL_ACHIVED,
  USER_SET_GOAL_DETAILS
} from '../QueryKeys/user.key';
const staleTime = 300000;
const cacheTime = 600000;

export const useGetUserGoal = (onSuccess, onError) => {
  return useQuery(USER_SET_GOAL_DETAILS, () => axiosInstance.get('user/user_goal'), {
    onSuccess,
    onError,
    select: (response) => response.data.data,
    staleTime,
    cacheTime: cacheTime,
    refetchOnWindowFocus: false
  });
};

export const useGetUserGoalAchived = (onSuccess, onError) => {
  return useQuery(USER_GOAL_ACHIVED, () => axiosInstance.get('user/goal_achieved'), {
    onSuccess,
    onError,
    select: (response) => response.data.data,
    staleTime,
    cacheTime: cacheTime,
    refetchOnWindowFocus: false
  });
};

export const useGetUserCmePointsAchived = (onSuccess, onError) => {
  return useQuery(USER_CME_POINTS_ACHIVED, () => axiosInstance.get('user/cme_points'), {
    onSuccess,
    onError,
    select: (response) => response.data.data,
    staleTime,
    cacheTime: cacheTime,
    refetchOnWindowFocus: false
  });
};

export const useGetUserActivity = (onSuccess, onError) => {
  return useQuery(USER_ACTIVITY_LIST, () => axiosInstance.get('user/user_activities'), {
    onSuccess,
    onError,
    select: (response) => response.data.data,

    cacheTime: cacheTime,
    refetchOnWindowFocus: false
  });
};



